import { styled, darkMode } from '@f8n-frontend/stitches';
import { CSSProperties, VariantProps } from '@stitches/react';
import { useRef } from 'react';

import useHasIntersected from 'hooks/use-has-intersected';
import { mapAvatarSizePropToImageSize } from 'utils/avatar';
import { AVATAR_RADII } from 'utils/avatar';
import { optimizeAsset } from 'utils/imgix';
import { getAvatarByPublicKey } from 'utils/users';

import { UnsafeAny } from 'types/utils';

type AvatarVariants = VariantProps<typeof AvatarImage>;

const AvatarImage = styled('div', {
  backgroundSize: 'cover',
  backgroundColor: '$black5',
  backgroundPosition: 'center',
  flexShrink: 0,
  boxShadow: 'inset 0 0 0 1px $colors$black5',

  [darkMode]: {
    backgroundColor: '$black100',
  },

  variants: {
    size: {
      0: {
        width: '$avatar0',
        height: '$avatar0',
      },
      1: {
        width: '$avatar1',
        height: '$avatar1',
      },
      2: {
        width: '$avatar2',
        height: '$avatar2',
      },
      3: {
        width: '$avatar3',
        height: '$avatar3',
      },
      4: {
        width: '$avatar4',
        height: '$avatar4',
      },
      5: {
        width: '$avatar5',
        height: '$avatar5',
      },
      6: {
        width: '$avatar6',
        height: '$avatar6',
      },
      7: {
        width: '$avatar7',
        height: '$avatar7',
      },
      8: {
        width: '$avatar8',
        height: '$avatar8',
      },
      9: {
        width: '$avatar9',
        height: '$avatar9',
      },
    },
    shape: {
      circle: {
        borderRadius: '$round',
      },
      square: {},
    },
  },

  compoundVariants: [
    {
      shape: 'square',
      size: 0,
      css: { borderRadius: `${AVATAR_RADII[0]}px` },
    },
    {
      shape: 'square',
      size: 1,
      css: { borderRadius: `${AVATAR_RADII[1]}px` },
    },
    {
      shape: 'square',
      size: 2,
      css: { borderRadius: `${AVATAR_RADII[2]}px` },
    },
    {
      shape: 'square',
      size: 3,
      css: { borderRadius: `${AVATAR_RADII[3]}px` },
    },
    {
      shape: 'square',
      size: 4,
      css: { borderRadius: `${AVATAR_RADII[4]}px` },
    },
    {
      shape: 'square',
      size: 5,
      css: { borderRadius: `${AVATAR_RADII[5]}px` },
    },
    {
      shape: 'square',
      size: 6,
      css: { borderRadius: `${AVATAR_RADII[6]}px` },
    },
    {
      shape: 'square',
      size: 7,
      css: { borderRadius: `${AVATAR_RADII[7]}px` },
    },
    {
      shape: 'square',
      size: 8,
      css: { borderRadius: `${AVATAR_RADII[8]}px` },
    },
    {
      shape: 'square',
      size: 9,
      css: { borderRadius: `${AVATAR_RADII[9]}px` },
    },
  ],

  defaultVariants: {
    size: 1,
    shape: 'circle',
  },
});

type Size = AvatarVariants['size'];
type Shape = AvatarVariants['shape'];

interface AvatarProps {
  alt?: string;
  css?: UnsafeAny;
  imageUrl: string | null | undefined;
  publicKey?: string;
  shape?: Shape;
  size?: Size;

  /**
   * Temporary prop to help with migration
   * TODO: remove once all avatars are migrated
   * */
  disableImgixOptimization?: boolean;
}

function AvatarBase(props: AvatarProps) {
  const {
    alt,
    imageUrl,
    size,
    publicKey,
    shape,
    css,
    disableImgixOptimization = false,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const hasIntersected = useHasIntersected(ref, {
    rootMargin: '10px 0px 0px 0px',
  });

  return (
    <AvatarImage
      ref={ref}
      aria-label={alt}
      css={css}
      role="image"
      shape={shape}
      size={size}
      style={getBackgroundStyle({
        imageUrl,
        publicKey,
        size,
        disableImgixOptimization,
        hasIntersected,
      })}
    />
  );
}

interface BackgroundStyleOptions {
  disableImgixOptimization?: AvatarProps['disableImgixOptimization'];
  imageUrl: string | null | undefined;
  publicKey: string | undefined;
  size: Size;
  hasIntersected: boolean;
}

const getBackgroundStyle = (options: BackgroundStyleOptions): CSSProperties => {
  const {
    disableImgixOptimization,
    imageUrl,
    hasIntersected,
    publicKey,
    size,
  } = options;

  if (imageUrl && disableImgixOptimization) {
    return {
      backgroundImage: `url(${imageUrl})`,
    };
  }

  if (imageUrl) {
    const imageSize = mapAvatarSizePropToImageSize(size);

    const optimizedImageUrl = optimizeAsset(imageUrl, {
      q: 50,
      w: imageSize,
      h: imageSize,
      fit: 'crop',
      dpr: 2,
    });

    return {
      backgroundImage: hasIntersected ? `url(${optimizedImageUrl})` : '',
    };
  }

  if (publicKey) {
    return {
      background: getAvatarByPublicKey(publicKey),
    };
  }

  return {};
};

const Avatar = Object.assign(AvatarBase, {
  Image: AvatarImage,
});

export default Avatar;
export type { AvatarVariants, AvatarProps };
