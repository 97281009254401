import Tippy, { TippyProps } from '@tippyjs/react';
import HeadlessTippy from '@tippyjs/react/headless';
import { ReactNode, useState } from 'react';

import 'tippy.js/animations/shift-away.css';

import Box from 'components/base/Box';
import ProfileCard from 'components/cards/profile/ProfileCard';
import SingletonPopoverContainer from 'components/popover/SingletonPopoverContainer';

import usePopoverContainerRef from 'hooks/use-popover-container-ref';
import {
  DEFAULT_TIPPY_PROPS,
  DEFAULT_SINGLETON_TIPPY_PROPS,
} from 'utils/popover';

import { PopoverPlacement, SingletonPopoverObject } from 'types/popover';

interface ProfileHoverCardProps {
  children: ReactNode;
  publicKey: string;
  delay?: TippyProps['delay'];
  offset?: TippyProps['offset'];
  placement?: TippyProps['placement'];
}

export default function ProfileHoverCard(props: ProfileHoverCardProps) {
  const { children, delay, publicKey, offset, placement } = props;

  const [shouldRender, setShouldRender] = useState(false);
  const popoverContainerRef = usePopoverContainerRef();

  return (
    <Tippy
      {...DEFAULT_TIPPY_PROPS}
      content={
        // Only render the popover content when the popover is triggered,
        // to avoid firing the queries inside this component before they are needed
        shouldRender ? <ProfileCard publicKey={publicKey} /> : null
      }
      delay={delay || DEFAULT_TIPPY_PROPS.delay}
      offset={offset || DEFAULT_TIPPY_PROPS.offset}
      placement={placement || DEFAULT_TIPPY_PROPS.placement}
      interactive
      animation="shift-away"
      onTrigger={() => setShouldRender(true)}
      touch={false}
      appendTo={popoverContainerRef.current}
    >
      <Box css={{ cursor: 'pointer', minWidth: 0 }}>{children}</Box>
    </Tippy>
  );
}

interface ProfileHoverCardSingletonProps {
  placement: PopoverPlacement;
  source: SingletonPopoverObject;
}

export function ProfileHoverCardSingleton(
  props: ProfileHoverCardSingletonProps
) {
  const { placement, source } = props;

  const popoverContainerRef = usePopoverContainerRef();

  return (
    <HeadlessTippy
      {...DEFAULT_SINGLETON_TIPPY_PROPS}
      singleton={source}
      render={(attributes, content: string) => {
        if (!content) {
          return null;
        }

        return (
          <SingletonPopoverContainer {...attributes}>
            <ProfileCard key={`popover-${content}`} publicKey={content} />
          </SingletonPopoverContainer>
        );
      }}
      appendTo={popoverContainerRef.current}
      interactive
      offset={[0, 16]}
      placement={placement}
      touch={false}
    />
  );
}
