import { styled } from '@f8n-frontend/stitches';
import { transitions } from '@f8n-frontend/stitches';

/**
 * A wrapper div for animating singleton popovers
 * Singleton popovers are used when multiple popopver triggers are very close to each other in the UI.
 *
 * @see https://github.com/atomiks/tippyjs-react#headless-singleton
 */
const SingletonPopoverContainer = styled('div', {
  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-placement^="bottom"]': {
      animation: `${transitions.fadeInDown} $transitions$1 $transitions$ease forwards`,
    },
    '&[data-placement^="top"]': {
      animation: `${transitions.fadeInUp} $transitions$1 $transitions$ease forwards`,
    },

    // TODO: add exit animation here
  },
});

export default SingletonPopoverContainer;
