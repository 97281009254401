import { addressesByChainId } from '@f8n/f8n-contracts';
import type { Address } from 'viem';

import { ExtractRecordKeysByValueType } from 'types/utils';

import { HIGHLIGHT_ADDRESSES, HighlightContract } from './highlight';

/**
 * This type includes all contract addresses for chains we support
 */
type AddressesByChainIdConfig = typeof addressesByChainId;

/**
 * This type filters out nested objects, and inludes only top-level contract names
 */
type AddressesByChainId = {
  [ChainId in keyof AddressesByChainIdConfig]: ExtractRecordKeysByValueType<
    AddressesByChainIdConfig[ChainId],
    Address
  >;
};

export function getContractAddress<
  AddressLookupChainId extends keyof AddressesByChainId,
>(options: {
  chainId: AddressLookupChainId;
  contractName: AddressesByChainId[AddressLookupChainId];
}): Address {
  return addressesByChainId[options.chainId][options.contractName] as Address;
}

export function getHighlightAddress<
  ContractName extends HighlightContract,
>(options: {
  contractName: ContractName;
  chainId: keyof (typeof HIGHLIGHT_ADDRESSES)[ContractName];
}): Address {
  return HIGHLIGHT_ADDRESSES[options.contractName][options.chainId] as Address;
}

const KnownOriginV2Address = '0xFBeef911Dc5821886e1dda71586d90eD28174B7d';
const AsyncBlueprintAddress = '0xc143bbfcDBdBEd6d454803804752a064A622C1F3';

/*
 * Hack to temporarily hide these imported contract addresses
 * until we're able to use the API to handle display logic
 */
export const TEMPORARILY_HIDDEN_CONTRACT_ADDRESSES = [
  KnownOriginV2Address,
  AsyncBlueprintAddress,
];
