import { compose, pipe, replace } from 'ramda';
import { match } from 'ts-pattern';

import { MIN_LIST_PRICE } from 'lib/constants';

import { Percent } from 'types/number';

import {
  castNanToZero,
  castToNumber,
  isBooleanType,
  isEmptyOrNil,
} from './helpers';
import { basisPointsToPercent, clampPercent } from './numbers';
import { pluralizeWord } from './strings';
import { formatEther } from './units';

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const integerFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const ethFormatterRounded = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
});

export const formatETHRounded = ethFormatterRounded.format;
export const formatInteger = integerFormatter.format;
export const formatNumber = numberFormatter.format;

const MIN_ROUNDED_ETH_VALUE = MIN_LIST_PRICE;
const BELOW_MIN_ROUNDED_ETH_VALUE_FALLBACK_TEXT = `< ${MIN_ROUNDED_ETH_VALUE}`;

const addEthSuffix = (val: string) => `${val} ETH`;

const formatZero = (val: string) => replace(/^0.00$/, '0')(val);

export const roundEth = (val: number) => {
  if (val > 0 && val < MIN_ROUNDED_ETH_VALUE) {
    return BELOW_MIN_ROUNDED_ETH_VALUE_FALLBACK_TEXT;
  }

  return formatETHRounded(val);
};

export const formatETHWithoutSuffix = compose(
  formatZero,
  roundEth,
  castNanToZero,
  castToNumber
);

export const formatETHWithSuffix = compose(
  addEthSuffix,
  formatETHWithoutSuffix
);

export const formatBigIntEthWithSuffix = pipe(formatEther, formatETHWithSuffix);

export function countDecimals(value: number): number {
  const hasNoValue = isEmptyOrNil(value);
  if (hasNoValue) {
    return 0;
  }
  if (value % 1 != 0) {
    return value.toString().split('.')?.[1]?.length || 0;
  }
  return 0;
}

export function addCommas(val: number): string {
  if (val) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
}

export function abbreviateValue(
  val: number,
  options: { roundThousands: boolean } = { roundThousands: false }
): string | number {
  if (val >= 1000000) {
    return (val / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
  }
  if (val >= 9999) {
    return (val / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  if (options.roundThousands && val >= 999) {
    return (val / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  if (val === 0) {
    return 0;
  }
  return addCommas(val);
}

export function formatBasisPoints(baseRate: number): string {
  return `${formatNumber(basisPointsToPercent(baseRate))}%`;
}

/**
 * Used to format aggregated ETH values, where the exact decimal value
 * becomes less important as the value increases.
 */
export const formatTotalEthValue = (value: number) =>
  match(value)
    .when(
      (value) => value === 0,
      () => '0'
    )
    .when(
      (value) => value > 0 && value < MIN_ROUNDED_ETH_VALUE,
      () => BELOW_MIN_ROUNDED_ETH_VALUE_FALLBACK_TEXT
    )
    .when(
      (value) => value < 1,
      Intl.NumberFormat('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
      }).format
    )
    .when(
      (value) => value < 100,
      Intl.NumberFormat('en', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 2,
      }).format
    )
    .when(
      (value) => value < 1_000,
      Intl.NumberFormat('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }).format
    )
    .when(
      (value) => value < 10_000,
      Intl.NumberFormat('en', {
        notation: 'compact',
        maximumFractionDigits: 2,
      }).format
    )
    .when(
      (value) => value < 100_000,
      Intl.NumberFormat('en', {
        notation: 'compact',
        maximumFractionDigits: 1,
      }).format
    )
    .otherwise(
      Intl.NumberFormat('en', {
        notation: 'compact',
      }).format
    );

export function formatMintPrice(
  mintPrice: number | null | undefined,
  options: { hasEthSuffix?: boolean } = {}
): string | null {
  if (mintPrice === null || mintPrice === undefined) {
    return null;
  }
  if (mintPrice === 0) {
    return 'Free';
  }
  if (isBooleanType(options.hasEthSuffix) && options.hasEthSuffix === false) {
    return formatETHWithoutSuffix(mintPrice);
  }

  return formatETHWithSuffix(mintPrice);
}
export const formatMintRatio = (options: {
  minted: number;
  supply: number | null;
}) => {
  const formattedMintCount = abbreviateValue(options.minted);

  if (options.supply === null) {
    return `${formattedMintCount} minted`;
  }

  const formattedSupplyCount = abbreviateValue(options.supply);

  return `${formattedMintCount} of ${formattedSupplyCount} minted`;
};

export function formatNftCount(count: number) {
  return `${formatNumber(count)} ${pluralizeWord('NFT', count)}`;
}

export function formatNftPrice(nftPrice: number): string {
  if (nftPrice === 0) {
    return 'Free';
  }

  return `${formatETHRounded(nftPrice)} ETH`;
}

export const getMintedSupplyPercent = (options: {
  minted: number;
  supply: number;
}): Percent => {
  return Math.round(clampPercent((options.minted / options.supply) * 100));
};

function roundUpToBeDivisibleBy(number: bigint, divisor: bigint) {
  const remainder = number % divisor;
  if (remainder === BigInt(0)) {
    // The number is already divisible by the divisor.
    return number;
  }
  const difference = divisor - remainder;
  const roundedNumber = number + difference;
  return roundedNumber;
}

/**
 * 0.0001 ETH as a BigInt
 */
const MIN_THRESHOLD = BigInt(100000000000000);

export function formatBidAmount(amount: bigint): string {
  if (amount < MIN_THRESHOLD) {
    return formatEther(amount);
  }

  const roundedNumber = roundUpToBeDivisibleBy(amount, MIN_THRESHOLD);

  return formatEther(roundedNumber);
}
