import { styled } from '@f8n-frontend/stitches';

import Text from './Text';

const Heading = styled('h2', Text, {
  fontWeight: '$semibold',
});

export const H1Heading = styled('h1', Heading, {});
export const H2Heading = styled('h2', Heading, {});
export const H3Heading = styled('h3', Heading, {});

export default Heading;
