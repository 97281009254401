import Box from 'components/base/Box';
import Text from 'components/base/Text';

import { abbreviateValue } from 'utils/formatters';

export type SocialGraphLabel = 'Followers' | 'Following';
interface ProfileFollowCountProps {
  count: number;
  label: SocialGraphLabel;
}

export default function ProfileSocialGraph(props: ProfileFollowCountProps) {
  const { count, label } = props;

  return (
    <Box>
      <Text
        weight="semibold"
        size={3}
        css={{
          color: '$black100',
          transition: 'color $1 $ease',
        }}
      >
        {abbreviateValue(count)}
      </Text>
      <ProfileSocialGraph.Label label={label} />
    </Box>
  );
}

type ProfileFollowCountLabelProps = {
  label: SocialGraphLabel;
};

ProfileSocialGraph.Label = function ProfileSocialGraphLabel(
  props: ProfileFollowCountLabelProps
) {
  return (
    <Text
      weight="semibold"
      size={0}
      css={{
        color: 'inherit',
        transition: 'color $1 $ease',
      }}
    >
      {props.label}
    </Text>
  );
};
