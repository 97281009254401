import { styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';

import Text from 'components/base/Text';

type MonoVariants = VariantProps<typeof Mono>;

const Mono = styled(Text, {
  fontFamily: '$mono',
  fontWeight: '$regular',
  variants: {
    size: {
      0: { fontSize: '$mono0' },
      1: { fontSize: '$mono1' },
      2: { fontSize: '$mono2' },
      3: { fontSize: '$mono3' },
      4: { fontSize: '$mono4' },
      5: { fontSize: '$mono5' },
      6: { fontSize: '$mono6' },
      7: { fontSize: '$mono7' },
    },
  },
});

export type { MonoVariants };
export default Mono;
