import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/is-following-user.generated';
import { connectedUserCache } from 'utils/cache';

import { UserFollow } from 'types/profile';

export type IsFollowing = UserFollow['isFollowing'];

async function getIsFollowingUser(
  variables: Generated.ApiIsFollowingUserVariables
): Promise<IsFollowing> {
  const fetchIsFollowingUser = Generated.useIsFollowingUser.fetcher(variables);
  const response = await fetchIsFollowingUser();

  return response.isFollowingUser;
}

export default function useIsFollowingUser(
  variables: Generated.ApiIsFollowingUserVariables,
  options: Pick<
    UseQueryOptions<IsFollowing, Error>,
    'enabled' | 'placeholderData'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: connectedUserCache.isFollowing(variables.accountAddress),
    queryFn: () => getIsFollowingUser(variables),
  });
}
