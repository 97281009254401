import { imgixProxyOrigin } from 'lib/assets';

import { ImgixOptimizeOptions, ImgixParams } from 'types/Assets';
import { VideoMediaAsset } from 'types/media';

/**
 * Note that images optimized by Imgix get cached on a CDN.
 * To clear that cache we can either use their UI for one-off purge tool.
 * @see https://dashboard.imgix.com/tools
 *
 * Or, programatically clear the cache (requires eng work to set this up)
 *
 * Or, simply bump the version number in this string (which creates a new cached version)
 *  */
export const IMGIX_CACHE_BUST_PARAM = 'v1';

export function isUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * These are the S3 buckets that we store raw assets in.
 * In order for them to work with Imgix, they must be configured as a source in the Imgix dashboard.
 *
 * @see https://dashboard.imgix.com/sources
 * @see https://docs.imgix.com/setup/creating-sources/amazon-s3
 */
const IMGIX_S3_SOURCE_ORIGIN_PREFIXES = [
  'https://f8n-staging.s3',
  'https://f8n-qa.s3',
  'https://f8n-production.s3',
];

export const canBeOptimizedViaImgixProxy = (imageUrl: string) => {
  return IMGIX_S3_SOURCE_ORIGIN_PREFIXES.some((origin) =>
    imageUrl.startsWith(origin)
  );
};

export const proxyViaImgix = (imageUrl: URL): URL => {
  const url = new URL(imageUrl);
  const imgixUrl = new URL(imgixProxyOrigin);

  imgixUrl.pathname = url.pathname;

  return imgixUrl;
};

const DEFAULT_IMGIX_OPTIONS: ImgixParams = {
  auto: 'format,compress',
  q: 70,
  /**
   * cs (Color Space) override to prevent auto `compress` from augmenting colors.
   *
   * Quoting from the imgix docs:
   * > "Adding cs=srgb will resolve color issues by adding the color space back"
   * @see https://docs.imgix.com/apis/rendering/auto/auto#compress
   */
  cs: 'srgb',
};

const applyImgixParams = (
  imageUrl: URL,
  options: ImgixOptimizeOptions = {}
): URL => {
  const url = new URL(imageUrl);

  const allOptions: ImgixParams = {
    ...DEFAULT_IMGIX_OPTIONS,
    ...options,
  };

  const params = new URLSearchParams();

  Object.entries(allOptions).forEach(([key, value]) => {
    if (value !== undefined) {
      params.set(key, String(value));
    }
  });

  params.set('fnd_key', IMGIX_CACHE_BUST_PARAM);
  url.search = params.toString();

  return url;
};

export const getDpr = () => {
  if (typeof window === 'undefined') {
    return 1;
  }

  return window.devicePixelRatio > 1 ? 2 : 1;
};

export const optimizeAsset = (
  imageUrl: string,
  imgixOptions: ImgixOptimizeOptions = {}
): string => {
  if (!isUrl(imageUrl)) {
    return imageUrl;
  }

  const url = new URL(imageUrl);

  /**
   * Handles legacy assets that point to S3.
   */
  if (canBeOptimizedViaImgixProxy(url.toString())) {
    const imgixUrl = proxyViaImgix(url);
    const optimizedImgixUrl = applyImgixParams(imgixUrl, imgixOptions);

    return optimizedImgixUrl.toString();
  }

  /**
   * Handles assets that are already pointing to Imgix.
   */
  if (url.host.includes('imgix.net')) {
    const optimizedImgixUrl = applyImgixParams(url, imgixOptions);

    return optimizedImgixUrl.toString();
  }

  return imageUrl;
};

/**
 * Used to optimize OG + Twitter meta images.
 */
export const optimizeMetaImage = (imageUrl: string) => {
  return optimizeAsset(imageUrl, {
    q: 70,
    w: 1200,
    h: 630,
    fit: 'crop',
    fm: 'jpg',
  });
};

export const mapImgixGifToMp4 = (
  assetUrl: string,
  options: ImgixOptimizeOptions = {}
): VideoMediaAsset => {
  /**
   * force original gif asset to mp4
   */
  const imgixOptions: ImgixOptimizeOptions = {
    ...options,
    fm: 'mp4',
  };

  const assetUrlObj = new URL(assetUrl);
  assetUrlObj.search = new URLSearchParams(
    imgixOptions as Record<string, string>
  ).toString();

  /**
   * force original gif asset to jpg
   */
  const posterOptions: ImgixOptimizeOptions = {
    ...options,
    fm: 'jpg',
  };

  const posterAssetUrlObj = new URL(assetUrl);
  posterAssetUrlObj.search = new URLSearchParams(
    posterOptions as Record<string, string>
  ).toString();

  return {
    src: assetUrlObj.toString(),
    poster: posterAssetUrlObj.toString(),
    type: 'video',
  };
};

export const mapImgixVectorToRaster = (assetUrl: string): string => {
  return optimizeAsset(assetUrl, {
    fm: 'auto',
  });
};
