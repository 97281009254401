import { darkMode, styled } from '@f8n-frontend/stitches';

import { UserLight } from 'types/Account';

import Box from './base/Box';
import Flex from './base/Flex';
import Text from './base/Text';
import { MemoizedAvatarStack } from './profiles/ProfileCollectors';

const StatLabel = styled(Text, {
  whiteSpace: 'nowrap',
  transition: 'color $1 $ease',
  marginBottom: '$1',
  [darkMode]: {
    color: '$black100',
  },
});
StatLabel.defaultProps = {
  color: 'dim',
  weight: 'regular',
};

const StatValue = styled(Text);
StatValue.defaultProps = {
  size: {
    '@bpxs': 3,
    '@initial': 4,
  },
  weight: 'semibold',
  color: 'strong',
};

type StatAvatarCountProps = {
  count: number;
  users: UserLight[];
  avatarsVariant?: 'blur' | 'raised';
};

function StatAvatarCount(props: StatAvatarCountProps) {
  return (
    <Flex
      css={{
        justifyContent: props.users.length > 0 ? 'center' : 'flex-start',
        alignItems: 'center',
      }}
    >
      <StatValue>{props.count}</StatValue>
      <Box
        css={{
          marginLeft: '$2',
          position: 'relative',
          zIndex: 2,
        }}
      >
        <MemoizedAvatarStack
          size={2}
          placement="bottom"
          users={props.users}
          variant={props.avatarsVariant || 'raised'}
        />
      </Box>
    </Flex>
  );
}

const StatRoot = styled('div', {
  textAlign: 'inherit',

  variants: {
    size: {
      0: {
        [`${StatLabel}`]: {
          fontSize: '$1',
        },
      },
      1: {
        [`${StatLabel}`]: {
          fontSize: '$2',
        },
      },
    },
    align: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
    },
  },

  defaultVariants: {
    align: 'left',
    size: 0,
  },
});

const Stat = {
  Root: StatRoot,
  Label: StatLabel,
  Value: StatValue,
  AvatarCountValue: StatAvatarCount,
};

export default Stat;
