import { sizes } from '@f8n/tokens';

import type { AvatarVariants } from 'components/base/Avatar';

import { isNumberType } from './helpers';

export const AVATAR_RADII = {
  0: 3,
  1: 4,
  2: 4,
  3: 6,
  4: 8,
  5: 9,
  6: 12,
  7: 14,
  8: 18,
  9: 24,
};

export const AVATAR_BORDERS = {
  0: 2,
  1: 2,
  2: 3,
  3: 4,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 9,
  9: 12,
};

export const mapAvatarSizePropToImageSize = (
  size: AvatarVariants['size']
): number => {
  // If size is a number, use it
  if (typeof size === 'number') {
    /**
     * the mapping between the design system component scale and
     * the desired asset size gets thrown off
     *
     * e.g the UserTag can have a size of 1 which gets mapped
     * to 20px rather than 32px.
     *
     * this fix nudges the size two up in the scale (if it exists)
     */
    return isNumberType(sizes.avatars[size + 2])
      ? sizes.avatars[size + 2]
      : sizes.avatars[size];
  }

  // otherwise, fall back to largest size
  // later, we can consider optimizing this by parsing the size prop object
  return sizes.avatars[9];
};
