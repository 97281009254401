import { useSingleton } from '@tippyjs/react/headless';
import { takeLast } from 'ramda';
import { memo } from 'react';

import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Heading from 'components/base/Heading';
import Text from 'components/base/Text';
import { UserAvatarVariants } from 'components/base/UserAvatar';
import UserStack, { UserStackInteractive } from 'components/follows/UserStack';

import { abbreviateValue } from 'utils/formatters';
import { isEmptyOrNil } from 'utils/helpers';
import { pluralizeWord } from 'utils/strings';

import { UserLight } from 'types/Account';
import { PopoverPlacement } from 'types/popover';

import { ProfileHoverCardSingleton } from './ProfileHoverCard';

interface ProfileCollectorsProps {
  collectors: UserLight[];
  collectorCount: number;
  onClick(): void;
}

export default function ProfileCollectors(props: ProfileCollectorsProps) {
  const { collectors, collectorCount, onClick } = props;

  const preferedCollectors = preferPopulatedProfiles(collectors);
  const collectorAvatars = takeLast(5, preferedCollectors);
  const hasNoCollectors = isEmptyOrNil(collectors);

  if (hasNoCollectors) {
    return null;
  }

  return (
    <Flex css={{ alignItems: 'center' }}>
      <Box css={{ position: 'relative', zIndex: 2 }}>
        <MemoizedAvatarStack
          users={collectorAvatars}
          variant="raised"
          placement="bottom"
          size={2}
        />
      </Box>
      <Heading
        onClick={onClick}
        color="strong"
        css={{
          cursor: 'pointer',
          marginLeft: '$2',
          whiteSpace: 'nowrap',
          '@hover': {
            '&:hover': {
              color: '$black70',
            },
          },
        }}
      >
        <Text as="span">{abbreviateValue(collectorCount)}</Text>
        {` `}
        <Text as="span">{pluralizeWord('collector', collectorCount)}</Text>
      </Heading>
    </Flex>
  );
}

/**
 * This pushes profiles with images/names to the END of the array
 * This is intentional because:
 *
 * - we then takeLast() to get items from the END of the array
 * - we reorder the items in CSS so that the items at the END are visually shown first
 */
const preferPopulatedProfiles = (collectors: UserLight[]): UserLight[] => {
  return collectors.sort((a, b) => {
    if (!a.name || !a.profileImageUrl) {
      return -1;
    }

    if (a.profileImageUrl && !b.profileImageUrl) {
      return 1;
    }

    if (a.name && !b.name) {
      return 1;
    }

    return 0;
  });
};

type MemoizedAvatarStackProps = {
  users: UserLight[];
  additionalUsersCount?: number;
  placement?: PopoverPlacement;
  size: UserAvatarVariants['size'];
  variant: UserAvatarVariants['variant'];
};

export const MemoizedAvatarStack = memo(function ProfileCollectorsPopovers(
  props: MemoizedAvatarStackProps
) {
  const { users, size, variant, placement = 'bottom-end' } = props;

  const [source, target] = useSingleton();

  return (
    <>
      <ProfileHoverCardSingleton source={source} placement={placement} />
      {props.additionalUsersCount && props.additionalUsersCount > 0 ? (
        <Flex css={{ alignItems: 'center' }}>
          <UserStackInteractive
            variant={variant}
            size={size}
            users={users}
            target={target}
          />

          <UserStack.CountBadge count={props.additionalUsersCount} />
        </Flex>
      ) : (
        <UserStackInteractive
          variant={variant}
          size={size}
          users={users}
          target={target}
        />
      )}
    </>
  );
});
