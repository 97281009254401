type Maybe<Type> = Type | null | undefined;

type Aggregate = {
  count: number;
};

export type DataWithAggregate = {
  aggregate?: Maybe<Aggregate>;
};

export const getAggregateCount = (value: Maybe<DataWithAggregate>): number => {
  if (!value) return 0;
  if (!value.aggregate) return 0;
  return value.aggregate.count || 0;
};
