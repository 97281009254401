import { useEffect, useState } from 'react';
import { useIntersection } from 'react-use';

export default function useHasIntersected(
  ref: React.RefObject<HTMLElement>,
  options: IntersectionObserverInit = {}
): boolean {
  const [hasIntersected, setHasIntersected] = useState(false);

  const intersection = useIntersection(ref, options);

  useEffect(() => {
    if (intersection?.isIntersecting) {
      setHasIntersected(true);
    }
  }, [intersection?.isIntersecting]);

  return hasIntersected;
}
