import { styled, darkMode } from '@f8n-frontend/stitches';

import { AVATAR_RADII } from 'utils/avatar';

const Block = styled('div', {
  borderRadius: '$1',
  width: '100%',
  height: '100%',
  color: 'transparent',

  variants: {
    variant: {
      fill: {
        backgroundColor: '$black5',
        [darkMode]: {
          backgroundColor: '$black20',
          backdropFilter: 'blur(10px)',
        },
      },
      outline: {
        border: '1px solid $black5',
      },
    },
    opaque: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      opaque: true,
      variant: 'fill',
      css: {
        backgroundColor: '$black5',
      },
    },
    {
      opaque: true,
      variant: 'outline',
      css: {
        borderColor: '$black5',
      },
    },
  ],

  defaultVariants: {
    variant: 'fill',
    opaque: false,
  },
});

const Circle = styled(Block, {
  borderRadius: '$round',
  flexGrow: 0,
  flexShrink: 0,
});

const Avatar = styled(Circle, {
  variants: {
    size: {
      0: {
        width: '$avatar0',
        height: '$avatar0',
      },
      1: {
        width: '$avatar1',
        height: '$avatar1',
      },
      2: {
        width: '$avatar2',
        height: '$avatar2',
      },
      3: {
        width: '$avatar3',
        height: '$avatar3',
      },
      4: {
        width: '$avatar4',
        height: '$avatar4',
      },
      5: {
        width: '$avatar5',
        height: '$avatar5',
      },
      6: {
        width: '$avatar6',
        height: '$avatar6',
      },
      7: {
        width: '$avatar7',
        height: '$avatar7',
      },
      8: {
        width: '$avatar8',
        height: '$avatar8',
      },
      9: {
        width: '$avatar9',
        height: '$avatar9',
      },
    },

    shape: {
      square: {},
      circle: {},
    },
  },

  compoundVariants: [
    {
      shape: 'square',
      size: 0,
      css: { borderRadius: `${AVATAR_RADII[0]}px` },
    },
    {
      shape: 'square',
      size: 1,
      css: { borderRadius: `${AVATAR_RADII[1]}px` },
    },
    {
      shape: 'square',
      size: 2,
      css: { borderRadius: `${AVATAR_RADII[2]}px` },
    },
    {
      shape: 'square',
      size: 3,
      css: { borderRadius: `${AVATAR_RADII[3]}px` },
    },
    {
      shape: 'square',
      size: 4,
      css: { borderRadius: `${AVATAR_RADII[4]}px` },
    },
    {
      shape: 'square',
      size: 5,
      css: { borderRadius: `${AVATAR_RADII[5]}px` },
    },
    {
      shape: 'square',
      size: 6,
      css: { borderRadius: `${AVATAR_RADII[6]}px` },
    },
    {
      shape: 'square',
      size: 7,
      css: { borderRadius: `${AVATAR_RADII[7]}px` },
    },
    {
      shape: 'square',
      size: 8,
      css: { borderRadius: `${AVATAR_RADII[8]}px` },
    },
    {
      shape: 'square',
      size: 9,
      css: { borderRadius: `${AVATAR_RADII[9]}px` },
    },
  ],

  defaultVariants: {
    size: 1,
  },
});

const Button = styled(Circle, {
  variants: {
    size: {
      0: {
        width: 90,
        height: '$formElement0',
      },
      1: {
        width: 120,
        height: '$formElement1',
      },
      2: {
        width: 140,
        height: '$formElement2',
      },
    },
  },

  defaultVariants: {
    size: 1,
  },
});
Button.defaultProps = {
  variant: 'outline',
};

const Tab = styled(Circle, {
  height: '$formElement0',
  width: 'max-content',
  flexGrow: 0,
  paddingX: '$4',
  display: 'flex',
  alignItems: 'center',

  [`${Circle}`]: {
    paddingX: '$2',
    marginLeft: '$1',
    marginRight: '-$2',
    width: '22px',
    height: '20px',
  },
});
Button.defaultProps = {
  variant: 'fill',
};

const Text = styled(Block, {
  color: 'transparent',
  width: 'max-content',
  height: 'auto',

  '*': {
    color: 'inherit !important',
  },
});

export const fillSkeletonText = (length: number) => {
  return Array.from({ length }).map(() => <>&ensp;</>);
};

const Skeleton = {
  Avatar,
  Button,
  Block,
  Circle,
  Tab,
  Text,
};

export default Skeleton;
