import { ElementType } from 'react';

import Button, { ButtonProps } from 'components/base/Button';
import { hasPublicKey, hasToken } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import useModal from 'hooks/use-modal';

type AuthedButtonProps = ButtonProps & {
  as?: ElementType;
};

/**
 * @summary ensures user is connected & signed
 * otherwise onClick
 */
export default function AuthedButton(props: AuthedButtonProps) {
  const { children, as, ...rest } = props;
  const auth = useAuth();
  const modal = useModal();

  if (!hasPublicKey(auth)) {
    /**
     * if a user is not connected, ask to connect
     * if user is connected, ask to sign
     *
     */
    return (
      <Button
        type="button"
        {...rest}
        as={as}
        onClick={() => {
          auth.startLogin();
        }}
      >
        {children}
      </Button>
    );
  }

  if (!hasToken(auth)) {
    return (
      <Button
        type="button"
        {...rest}
        as={as}
        onClick={() => {
          modal.setModal({ type: 'AUTH_PRE_SIGN' });
        }}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button type="button" {...rest}>
      {children}
    </Button>
  );
}
