import { useCallback } from 'react';

import useAnalytics from 'contexts/analytics/useAnalytics';

import { EventName } from 'types/Analytics';

type SegmentEvent<Payload extends Record<string, unknown>> = {
  eventName: EventName;
  payload?: Payload;
};

/** @deprecated use useAnalytics instead, this is just a wrapper to keep the interface backwards compatible */
export default function useSegmentEvent() {
  const analytics = useAnalytics();

  const sendSegmentEvent = useCallback(
    <Payload extends Record<string, unknown>>(event: SegmentEvent<Payload>) => {
      analytics.legacyTrack(event.eventName, event.payload || {});
    },
    [analytics]
  );

  return sendSegmentEvent;
}
