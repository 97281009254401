import Heading from 'components/base/Heading';
import Mono from 'components/base/Mono';
import Text from 'components/base/Text';

import { getUsernameOrAddressInfo } from 'utils/helpers';

import { Account } from 'types/Account';

interface CreatorCardHeadingProps {
  user: Omit<Account, 'email'>;
}

export default function CreatorCardHeading(props: CreatorCardHeadingProps) {
  const { user } = props;

  const { isAddress, usernameOrAddress, nameOrUsername, hasName } =
    getUsernameOrAddressInfo(user);

  if (isAddress) {
    return <Mono size={4}>{usernameOrAddress}</Mono>;
  }

  if (!hasName) {
    return (
      <Heading size={5} css={{ display: 'flex', minWidth: 0 }}>
        <Text color="dim" ellipsis>
          {usernameOrAddress}
        </Text>
      </Heading>
    );
  }

  return (
    <Heading css={{ lineHeight: '$0' }} size={5} ellipsis>
      {nameOrUsername}
    </Heading>
  );
}
