import Flex from 'components/base/Flex';
import Text from 'components/base/Text';

import { getUsernameOrAddressInfo } from 'utils/helpers';

import { Account } from 'types/Account';

interface CreatorCardSubheadingProps {
  user: Omit<Account, 'email'>;
}

export default function CreatorCardSubheading(
  props: CreatorCardSubheadingProps
) {
  const { user } = props;

  const { isAddress, usernameOrAddress, hasName } =
    getUsernameOrAddressInfo(user);

  if (isAddress || !hasName) {
    return null;
  }

  return (
    <Flex css={{ minWidth: 0 }}>
      <Text size={1} color="dim" weight="semibold" ellipsis>
        {usernameOrAddress}
      </Text>
    </Flex>
  );
}
