import {
  formatEther as viemFormatEther,
  parseEther as viemParseEther,
  parseUnits as viemParseUnits,
} from 'viem';

export const formatEther = viemFormatEther;

export const parseEther = viemParseEther;

export const parseUnits = viemParseUnits;
