import Mono from 'components/base/Mono';
import Text, { TextVariants } from 'components/base/Text';

import { getUsernameOrAddressInfo } from 'utils/helpers';

import { Account, UserMicro } from 'types/Account';
import { ResponsiveProp } from 'types/stitches';

export type UserTextVariant = 'prefer-display-name' | 'prefer-username';

export type UserTextSize = ResponsiveProp<0 | 1 | 2 | 3>;

type UserTextProps = {
  color?: TextVariants['color'];
  variant: UserTextVariant;
  user: UserMicro | Account;
  size?: UserTextSize;
  weight?: TextVariants['weight'];
};

/** Shows a user's name, username, or address. */
export default function UserText(props: UserTextProps) {
  const { color, variant, size, user, weight = 'semibold' } = props;

  const { nameOrUsername, usernameOrAddress, hasUsername, hasName } =
    getUsernameOrAddressInfo(user);

  if (!hasUsername && !hasName) {
    return (
      <Mono color={color} size={size}>
        {usernameOrAddress}
      </Mono>
    );
  }

  return (
    <Text color={color} size={size} weight={weight} ellipsis>
      {variant === 'prefer-display-name' ? nameOrUsername : usernameOrAddress}
    </Text>
  );
}
