import { GraphQLClient } from 'graphql-request';

import { ENV } from 'utils/env';

export const fndHasuraClient = (): GraphQLClient => {
  const graphQLURL = new URL(ENV.HASURA_URL);

  // If this is being user server-side
  if (typeof window === 'undefined') {
    const HASURA_GRAPHQL_ADMIN_SECRET = process.env.HASURA_GRAPHQL_ADMIN_SECRET;

    return new GraphQLClient(graphQLURL.href, {
      fetch,
      headers: {
        'x-hasura-admin-secret': HASURA_GRAPHQL_ADMIN_SECRET as string,
        'x-hasura-role': 'backend',
      },
    });
  } else {
    return new GraphQLClient(graphQLURL.href, { fetch });
  }
};

export const hasuraFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  _options?: unknown // appeases TS. Generated fetchers pass a third argument.
): (() => Promise<TData>) => {
  return async () => {
    const client = fndHasuraClient();
    return await client.request<TData, TVariables>(query, variables);
  };
};
