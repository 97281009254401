import { ICON_SIZE_MAP } from '@f8n/icons/dist/helpers/size';
import { styled } from '@f8n-frontend/stitches';
import { darkMode } from '@f8n-frontend/stitches';
import { ComponentProps } from '@stitches/react';

import { SPINNER_TARGET } from 'components/Spinner';

const Badge = styled('div', {
  lineHeight: '$1',
  letterSpacing: '$0',
  whiteSpace: 'nowrap',
  fontWeight: '$medium',

  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: '$round',
  border: '1px solid transparent',

  variants: {
    radius: {
      round: {
        borderRadius: '$round',
      },
      sharp: {
        borderRadius: '$1',
      },
    },
    mono: {
      true: {
        fontFamily: '$mono',
      },
      false: {
        fontFamily: '$body',
      },
    },
    uppercase: {
      true: {
        letterSpacing: '$1',
        textTransform: 'uppercase',
      },
    },
    size: {
      // TODO: make this size=0
      micro: {
        gap: '$1',
        fontSize: '9px',
        paddingX: '$1',
        paddingY: '0px',

        svg: {
          width: '10px',
          height: '10px',
        },
      },
      0: {
        gap: '$1',
        fontSize: '$0',
        paddingX: '$2',
        paddingY: '4px',

        svg: {
          width: '10px',
          height: '10px',
        },
      },
      1: {
        gap: '6px',
        fontSize: '$1',
        paddingX: '12px',
        paddingY: '6px',

        svg: {
          width: ICON_SIZE_MAP[0],
          height: ICON_SIZE_MAP[0],
        },

        [SPINNER_TARGET.selector]: {
          width: ICON_SIZE_MAP[0],
          height: ICON_SIZE_MAP[0],
        },
      },
      2: {
        gap: '$2',
        fontSize: '$2',
        paddingX: '$4',
        paddingY: '6px',

        svg: {
          width: ICON_SIZE_MAP[1],
          height: ICON_SIZE_MAP[1],
        },

        [SPINNER_TARGET.selector]: {
          width: ICON_SIZE_MAP[1],
          height: ICON_SIZE_MAP[1],
        },
      },
    },
    variant: {
      primary: {
        color: '$white100',
        backgroundColor: '$black100',
      },
      outline: {
        color: '$black100',
        borderColor: '$black10',
      },
      new: {
        color: '$white100',
        backgroundColor: '$blue4',
      },
      light: {
        color: '$black70',
        backgroundColor: '$black5',

        [darkMode]: {
          color: '$black80',
          backgroundColor: '$black15',
        },
      },
      strong: {
        color: '$black100',
        backgroundColor: '$black4',
        fontWeight: '$semibold',
      },
      blur: {
        color: '$white100',
        backgroundColor: '$white20',
        backdropFilter: 'blur(10px)',

        [darkMode]: {
          color: '$black100',
          backgroundColor: '$black20',
        },
      },
      white: {
        color: '$black100',
        backgroundColor: '$white100',
      },
      subtle: {
        color: '$black60',
        backgroundColor: 'transparent',
        borderColor: '$black10',
      },
      blue: {
        color: '$blue5',
        backgroundColor: '$blue1',

        [darkMode]: {
          color: '#15A8FF',
          backgroundColor: '#15A8FF4D',
        },
      },
      purple: {
        color: '$purple5',
        backgroundColor: '$purple0',
      },
      orange: {
        color: '$orange5',
        backgroundColor: '$orange0',

        [darkMode]: {
          color: '#C5AA4A',
          backgroundColor: '#C5AA4A4D',
        },
      },
      pink: {
        color: '$pink4',
        backgroundColor: '$pink0',
      },
      red: {
        color: '$red4',
        backgroundColor: '$red0',
      },
      green: {
        color: '$green5',
        backgroundColor: '$green1',
      },
      gold: {
        color: '#695924',
        backgroundColor: '#E9E2C9',
      },
      admin: {
        color: '$white100',
        backgroundColor: '$adminOnlyStrong',

        [darkMode]: {
          color: '$black100',
        },
      },
    },
  },
});

Badge.defaultProps = {
  size: 0,
  mono: false,
  variant: 'light',
};

export type BadgeProps = ComponentProps<typeof Badge>;
export default Badge;
