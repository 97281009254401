import { useEffect, useRef } from 'react';

import { POPOVER_CONTAINER_ID } from 'utils/popover';

export default function usePopoverContainerRef() {
  const portalContainerRef = useRef<HTMLElement | undefined>(undefined);

  useEffect(() => {
    const container = document.getElementById(POPOVER_CONTAINER_ID);
    if (!container) return;
    portalContainerRef.current = container;
  }, []);

  return portalContainerRef;
}
