import { styled } from '@f8n-frontend/stitches';
import { VariantProps } from '@stitches/react';

import Avatar, { AvatarProps } from 'components/base/Avatar';

import { AVATAR_BORDERS } from 'utils/avatar';

export type UserAvatarVariants = VariantProps<typeof UserOversizedBorder>;
type Variant = UserAvatarVariants['variant'];

export type UserAvatarProps = Omit<AvatarProps, 'shape'> & {
  variant?: Variant;
};

export default function UserAvatar(props: UserAvatarProps) {
  const { variant, size, ...rest } = props;

  if (variant === 'base') {
    return <Avatar size={size} shape="circle" {...rest} />;
  }

  return (
    <UserOversizedBorder variant={variant} size={size}>
      <Avatar size={size} shape="circle" {...rest} />
    </UserOversizedBorder>
  );
}

UserAvatar.defaultProps = {
  variant: 'base',
};

const UserOversizedBorder = styled('div', {
  borderRadius: '$round',
  variants: {
    variant: {
      // This is added here to simplify the typing of the parent
      base: {},
      blur: {
        backgroundColor: '$white20',
        backdropFilter: 'blur(10px)',
        [`${Avatar.Image}`]: {
          boxShadow: 'none',
        },
      },
      raised: {
        boxShadow: '$soft1',
        backgroundColor: '$white100',
      },
      white: {
        backgroundColor: '$white100',
      },
      black: {
        backgroundColor: '$black100',
      },
    },
    size: {
      0: { padding: `${AVATAR_BORDERS[0]}px` },
      1: { padding: `${AVATAR_BORDERS[1]}px` },
      2: { padding: `${AVATAR_BORDERS[2]}px` },
      3: { padding: `${AVATAR_BORDERS[3]}px` },
      4: { padding: `${AVATAR_BORDERS[4]}px` },
      5: { padding: `${AVATAR_BORDERS[5]}px` },
      6: { padding: `${AVATAR_BORDERS[6]}px` },
      7: { padding: `${AVATAR_BORDERS[7]}px` },
      8: { padding: `${AVATAR_BORDERS[8]}px` },
      9: { padding: `${AVATAR_BORDERS[9]}px` },
    },
  },
  defaultVariants: {
    variant: 'base',
  },
});
