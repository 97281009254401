import { sizes } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import Tippy from '@tippyjs/react/headless';
import NextLink from 'next/link';

import Badge from 'components/base/Badge';
import Box from 'components/base/Box';
import Skeleton from 'components/base/Skeleton';
import UserAvatar, { UserAvatarVariants } from 'components/base/UserAvatar';

import { getUsernameOrAddress } from 'utils/helpers';

import { UserLight } from 'types/Account';
import { SingletonPopoverObject } from 'types/popover';

interface UserStackProps<T> {
  users: T[];
  variant?: UserAvatarVariants['variant'];
  size?: UserAvatarVariants['size'];
  disableLinks?: boolean;
}

interface UserStackElementProps<T> {
  user: T;
  variant: UserAvatarVariants['variant'];
  size: UserAvatarVariants['size'];
  disableLinks?: boolean;
}

export default function UserStack<T extends UserLight>(
  props: UserStackProps<T>
) {
  const { users, variant = 'raised', size = 2, disableLinks } = props;

  return (
    <ReverseStack size={props.size}>
      {users.map((user) => (
        <UserStackElement
          key={user.publicKey}
          user={user}
          variant={variant}
          size={size}
          disableLinks={disableLinks}
        />
      ))}
    </ReverseStack>
  );
}

type UserStackSkeletonProps = {
  expectedItems: number;
  size: UserAvatarVariants['size'];
};

UserStack.Skeleton = function UserStackSkeleton(props: UserStackSkeletonProps) {
  const { expectedItems, size } = props;

  // TODO: adjust the skeleton so it includes the border from UserAvatar
  return (
    <ReverseStack size={size}>
      {[...Array(expectedItems)].map((_, index) => (
        <Skeleton.Block
          key={index}
          css={{ borderRadius: '100%', width: 30, height: 30 }}
        />
      ))}
    </ReverseStack>
  );
};

/**
 * this value will give us a 45% overlap on avatars in the stack
 */
const OVERLAP_MULTIPLIER = 0.45;

const ReverseStack = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',

  variants: {
    size: {
      0: {
        '& > *:not(:first-child)': {
          /**
           * equals -16 * 0.45
           */
          marginRight: -sizes.avatars[0] * OVERLAP_MULTIPLIER,
        },
      },
      1: {
        '& > *:not(:first-child)': {
          marginRight: -sizes.avatars[1] * OVERLAP_MULTIPLIER,
        },
      },
      2: {
        '& > *:not(:first-child)': {
          marginRight: -sizes.avatars[2] * OVERLAP_MULTIPLIER,
        },
      },
      3: {
        '& > *:not(:first-child)': {
          marginRight: -sizes.avatars[3] * OVERLAP_MULTIPLIER,
        },
      },
      4: {
        '& > *:not(:first-child)': {
          marginRight: -sizes.avatars[4] * OVERLAP_MULTIPLIER,
        },
      },
      5: {
        '& > *:not(:first-child)': {
          marginRight: -sizes.avatars[5] * OVERLAP_MULTIPLIER,
        },
      },
      6: {},
      7: {},
      8: {},
      9: {},
    },
  },
});

type UserStackInteractiveProps<T> = {
  target: SingletonPopoverObject;
} & UserStackProps<T>;

export function UserStackInteractive<UserType extends UserLight>(
  props: UserStackInteractiveProps<UserType>
) {
  const { users, variant, target, size = 2 } = props;

  return (
    <ReverseStack size={size}>
      {users.map((user) => (
        <Tippy key={user.publicKey} content={user.publicKey} singleton={target}>
          <Box>
            <UserStackElement user={user} variant={variant} size={size} />
          </Box>
        </Tippy>
      ))}
    </ReverseStack>
  );
}

function UserStackStatic<T extends UserLight>(props: UserStackProps<T>) {
  const { users, variant = 'raised', size = 2 } = props;

  return (
    <ReverseStack size={size}>
      {users.map((user) => (
        <UserAvatar
          key={user.publicKey}
          publicKey={user.publicKey}
          imageUrl={user.profileImageUrl}
          size={size}
          variant={variant}
        />
      ))}
    </ReverseStack>
  );
}

function UserStackElement<T extends UserLight>(
  props: UserStackElementProps<T>
) {
  const { disableLinks, user, variant, size } = props;

  if (disableLinks) {
    return (
      <UserAvatar
        publicKey={user.publicKey}
        imageUrl={user.profileImageUrl}
        size={size}
        variant={variant}
      />
    );
  }

  return (
    <NextLink href={`/${getUsernameOrAddress(user)}`} prefetch={false}>
      <a aria-label={user.name || user.username || ''}>
        <UserAvatar
          publicKey={user.publicKey}
          imageUrl={user.profileImageUrl}
          size={size}
          variant={variant}
        />
      </a>
    </NextLink>
  );
}

function UserStackCountBadge(props: { onClick?: () => void; count: number }) {
  return (
    <Badge
      size={0}
      variant="white"
      css={{
        transform: 'translateX(-35%)',
        fontWeight: '$semibold',
        boxShadow: '$soft0',
        cursor: 'pointer',
        paddingLeft: '$1',
        maxHeight: 20,
      }}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      +{props.count}
    </Badge>
  );
}

UserStack.ReverseStack = ReverseStack;
UserStack.CountBadge = UserStackCountBadge;
UserStack.Static = UserStackStatic;
