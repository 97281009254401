import NextLink from 'next/link';

import Avatar from 'components/base/Avatar';
import Box from 'components/base/Box';
import Flex from 'components/base/Flex';
import Grid from 'components/base/Grid';
import Link from 'components/base/Link';
import Skeleton from 'components/base/Skeleton';
import FollowButton from 'components/follows/FollowButton';
import MutualFollowers from 'components/follows/MutualFollowers';
import ProfileSocialGraph, {
  SocialGraphLabel,
} from 'components/profiles/ProfileSocialGraph';
import { hasPublicKey, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import useUserByPublicKey from 'hooks/queries/api/use-user-by-public-key';
import { useUserFollowState } from 'hooks/queries/api/use-user-follow-state';
import useUserMutualFollows from 'hooks/use-user-mutual-follows';
import { getUsernameOrAddress } from 'utils/helpers';
import { secondsToMs } from 'utils/numbers';
import { ProfileDeepLinkModalParam } from 'utils/profile';
import { getPath } from 'utils/router';

import { UserLight } from 'types/Account';

import CreatorCardHeading from '../creator/CreatorCardHeading';
import CreatorCardSubheading from '../creator/CreatorCardSubheading';

interface ProfileCard {
  publicKey: string;
}

export default function ProfileCard(props: ProfileCard) {
  const { publicKey } = props;
  const auth = useAuth();

  const currentUserPublicKey = hasPublicKey(auth) ? auth.publicKey : undefined;

  const isOwnProfile = isMyAddress(auth, publicKey);

  const { data: user, isLoading: userLoading } = useUserByPublicKey(
    { publicKey },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: secondsToMs(5),
    }
  );

  const { data: followData } = useUserFollowState(
    { publicKey },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: secondsToMs(5),
    }
  );

  const followerCount = followData?.followerCount ?? 0;
  const followingCount = followData?.followingCount ?? 0;

  const hasNoFollowers = followData && followerCount === 0;

  const { data: mutualFollows, isLoading } = useUserMutualFollows(
    {
      publicKey,
      currentUserPublicKey: currentUserPublicKey as string,
      limit: 3,
      offset: 0,
    },
    {
      enabled: Boolean(currentUserPublicKey),
    }
  );

  return (
    <Box
      css={{
        boxShadow: '$regular2',
        backgroundColor: '$white100',
        minWidth: 340,
        maxWidth: 340,
        borderRadius: '$3',
        color: '$black100',
        padding: '$6',
      }}
    >
      <Box css={{ paddingBottom: '$5' }}>
        <Box css={{ position: 'relative' }}>
          <NextLink
            href={user ? `/${getUsernameOrAddress(user)}` : '#'}
            passHref
          >
            <Link
              css={{
                textDecoration: 'none',
                display: 'block',
                color: 'currentcolor',
              }}
            >
              <Box css={{ marginBottom: '$4' }}>
                <Avatar
                  size={7}
                  imageUrl={user?.profileImageUrl}
                  publicKey={user?.publicKey}
                />
              </Box>

              {userLoading ? (
                <CardHeadingSkeleton />
              ) : user ? (
                <Grid css={{ gap: 5, minWidth: 0 }}>
                  <CreatorCardHeading user={user} />
                  <CreatorCardSubheading user={user} />
                </Grid>
              ) : null}
            </Link>
          </NextLink>

          {!isOwnProfile && (
            <Box css={{ position: 'absolute', top: 0, right: 0 }}>
              <FollowButton
                isInitiallyFollowing={followData?.isFollowing}
                publicKey={publicKey}
                size={0}
              />
            </Box>
          )}
        </Box>

        {currentUserPublicKey && !isOwnProfile && (
          <Grid css={{ gap: '$6', marginTop: '$4' }}>
            <Flex
              css={{
                alignItems: 'center',
                display: hasNoFollowers ? 'none' : 'flex',
              }}
            >
              <MutualFollowers
                key={publicKey}
                publicKey={publicKey}
                currentUserPublicKey={currentUserPublicKey}
                otherFollowersVariant="DEEP_LINK_TO_MODAL"
                mutualFollows={mutualFollows}
                isLoading={isLoading}
                variant="MESSAGE"
                size={1}
              />
            </Flex>
          </Grid>
        )}
      </Box>
      <Flex
        css={{
          paddingTop: '$4',
          borderTop: 'solid 1px $black5',
          gap: '$6',
        }}
      >
        <SocialGraphCount
          user={user}
          count={followingCount}
          label="Following"
          tab="following"
          isLoading={userLoading}
        />
        <SocialGraphCount
          user={user}
          count={followerCount}
          label="Followers"
          tab="followers"
          isLoading={userLoading}
        />
      </Flex>
    </Box>
  );
}

function CardHeadingSkeleton() {
  return (
    <Grid css={{ gap: 5, marginBottom: '$4' }}>
      <Skeleton.Block css={{ height: 36, width: 200 }} />
      <Skeleton.Block css={{ height: 21, width: 120 }} />
    </Grid>
  );
}

interface SocialGraphCountProps {
  user: UserLight | undefined;
  count: number;
  label: SocialGraphLabel;
  tab: ProfileDeepLinkModalParam;
  isLoading: boolean;
}

function SocialGraphCount(props: SocialGraphCountProps) {
  const { user, count, label, tab, isLoading } = props;
  return (
    <NextLink
      href={
        user ? getPath.profile.follows(getUsernameOrAddress(user), tab) : '#'
      }
      passHref
    >
      <Link
        css={{
          marginRight: '$4',
          cursor: 'pointer',
          textDecoration: 'none',
          color: '$black50',
          '@media (hover: hover)': {
            '&:hover': {
              color: '$black100',
            },
          },
        }}
      >
        {isLoading ? (
          <Box css={{ display: 'flex', flexDirection: 'column', gap: '$2' }}>
            <Skeleton.Block
              css={{ height: 24, width: label === 'Following' ? 80 : 60 }}
            />
            <ProfileSocialGraph.Label label={props.label} />
          </Box>
        ) : (
          <ProfileSocialGraph label={label} count={count} />
        )}
      </Link>
    </NextLink>
  );
}
