import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/api/queries/user-follow-state.generated';

type UserFollowState = {
  isFollowing: boolean;
  followerCount: number;
  followingCount: number;
};

async function getUserFollowState(
  variables: Generated.ApiUserFollowStateVariables
): Promise<UserFollowState> {
  const fetchUserFollowState = Generated.useUserFollowState.fetcher(variables);
  const response = await fetchUserFollowState();
  return response.userFollowState;
}

export function useUserFollowState(
  variables: Generated.ApiUserFollowStateVariables,
  options: Pick<
    UseQueryOptions<UserFollowState, Error>,
    'enabled' | 'refetchOnWindowFocus' | 'refetchOnMount' | 'staleTime'
  > = {}
) {
  return useQuery({
    ...options,
    queryKey: ['user', 'follow-state', variables],
    queryFn: () => getUserFollowState(variables),
  });
}
