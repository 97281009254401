import { darkMode } from '@f8n-frontend/stitches';

import Text, { TextProps } from './Text';

type DashProps = {
  size?: TextProps['size'];
};

export default function Dash(props: DashProps) {
  return (
    <Text
      css={{ color: '$black20', [darkMode]: { color: '$black60' } }}
      weight="regular"
      size={props.size}
    >
      &mdash;
    </Text>
  );
}
