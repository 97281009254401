import { UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/hasura/queries/user-mutual-followers.generated';
import { getAggregateCount } from 'utils/aggregate';
import { secondsToMs } from 'utils/numbers';

type MutualFollowUser = NonNullable<
  Generated.UserMutualFollows['user']
>['mutualFollows'][number]['user'];

export interface UserMutualFollows {
  avatars: MutualFollowUser[];
  count: number;
}

export default function useUserMutualFollows(
  variables: Generated.UserMutualFollowsVariables,
  options: Pick<
    UseQueryOptions<Generated.UserMutualFollows, Error, UserMutualFollows>,
    'enabled'
  > = {}
) {
  return Generated.useUserMutualFollows(variables, {
    ...options,
    staleTime: secondsToMs(30),
    select: (data) => {
      const avatars = data.user?.mutualFollows
        ? data.user.mutualFollows.map((follow) => {
            return follow.user;
          })
        : [];

      const count = getAggregateCount(data.user?.mutualFollowsCount);

      return {
        avatars,
        count,
      };
    },
  });
}
