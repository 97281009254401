import type { Address } from 'viem';

import { parseEther } from '../utils/units';

import { ChainId, MAINNET_CHAIN_IDS, TESTNET_CHAIN_IDS } from './chains';

export type HighlightContract = 'mintManager' | 'dutchAuctionMechanic';

/**
 * @see https://support.highlight.xyz/knowledge-base/highlight-toolkit/highlight-protocol
 */
export const HIGHLIGHT_ADDRESSES: Record<
  HighlightContract,
  Record<ChainId, Address>
> = {
  mintManager: {
    [MAINNET_CHAIN_IDS.base]: `0x8087039152c472Fa74F47398628fF002994056EA`,
    [MAINNET_CHAIN_IDS.mainnet]: `0x1bf979282181f2b7a640d17aB5D2e25125F2de5e`,
    [TESTNET_CHAIN_IDS.baseSepolia]: `0x41cbab1028984A34C1338F437C726de791695AE8`,
    [TESTNET_CHAIN_IDS.sepolia]: `0xd698911B1Bb2a9c849Bf5e2604aF110766f396b6`,
  },
  dutchAuctionMechanic: {
    [MAINNET_CHAIN_IDS.base]: `0xA748BE280C9a00edaF7d04076FE8A93c59e95B03`,
    [MAINNET_CHAIN_IDS.mainnet]: `0x94Fa6e7Fc2555aDA63eA56cfFF425558360F0074`,
    [TESTNET_CHAIN_IDS.baseSepolia]: `0x4821B6e9aC0CCC590acCe2442bb6BB32388C1CB7`,
    [TESTNET_CHAIN_IDS.sepolia]: `0xceBc3B3134FbEF95ED13AEcdF997D4371d022385`,
  },
};

/**
 * Highlight charges a mint fee for every mint. These fees must be added to the value sent to their contracts to mint.
 */
export const HIGHLIGHT_MINT_FEE = parseEther('0.0008');
