import { styled } from '@f8n-frontend/stitches';
import Tippy, { useSingleton } from '@tippyjs/react/headless';
import NextLink from 'next/link';
import { memo } from 'react';

import Box from 'components/base/Box';
import Link from 'components/base/Link';
import Text from 'components/base/Text';
import { UserAvatarVariants } from 'components/base/UserAvatar';
import { ProfileHoverCardSingleton } from 'components/profiles/ProfileHoverCard';
import UserText from 'components/users/UserText';

import { UserMutualFollows as UserMutualFollowsBaseType } from 'gql/hasura/queries/user-mutual-followers.generated';
import { UserMutualFollows } from 'hooks/use-user-mutual-follows';
import { abbreviateValue } from 'utils/formatters';
import { getUsernameOrAddress, noop } from 'utils/helpers';
import { getPath } from 'utils/router';

import { SingletonPopoverObject } from 'types/popover';

import UserStack, { UserStackInteractive } from './UserStack';

type MutualFollowersBaseProps = {
  publicKey: string;
  currentUserPublicKey: string | null;
  hasPopovers?: boolean;
  variant: 'BADGE' | 'MESSAGE';
  avatarCount?: 3 | 5;
  mutualFollows?: UserMutualFollows;
  isLoading: boolean;
  size: UserAvatarVariants['size'];
};

type MutualFollowersProps =
  | ({
      otherFollowersVariant: 'SHOW_MODAL';
      onOtherFollowersClick(): void;
    } & MutualFollowersBaseProps)
  | ({
      otherFollowersVariant: 'DEEP_LINK_TO_MODAL';
      onOtherFollowersClick?(): void;
    } & MutualFollowersBaseProps);

type UserAvatar = NonNullable<
  UserMutualFollowsBaseType['user']
>['mutualFollows'][number]['user'];

export default function MutualFollowers(props: MutualFollowersProps) {
  const {
    currentUserPublicKey,
    hasPopovers = false,
    otherFollowersVariant,
    onOtherFollowersClick = noop,
    publicKey,
    variant,
    mutualFollows,
    isLoading,
    size,
  } = props;

  if (!currentUserPublicKey) {
    return null;
  }

  if (isLoading) {
    return (
      <UserStack.Skeleton
        size={size}
        expectedItems={variant === 'MESSAGE' ? 3 : 5}
      />
    );
  }

  if (!mutualFollows || mutualFollows.count === 0) {
    return (
      <MutualFollowersText>
        Not followed by anyone you follow
      </MutualFollowersText>
    );
  }

  if (hasPopovers) {
    return (
      <MemoizedMutualFollowerPopoverLinks
        avatars={mutualFollows.avatars}
        count={mutualFollows.count}
        otherFollowersVariant={otherFollowersVariant}
        onOtherFollowersClick={onOtherFollowersClick}
        publicKey={publicKey}
        variant={variant}
        size={size}
      />
    );
  }

  return (
    <MutualFollowerLinks
      avatars={mutualFollows.avatars}
      count={mutualFollows.count}
      otherFollowersVariant={otherFollowersVariant}
      onOtherFollowersClick={onOtherFollowersClick}
      publicKey={publicKey}
      variant={variant}
      size={size}
    />
  );
}

type MutualFollowerLinksProps = {
  avatars: UserAvatar[];
  count: number;
  publicKey: string;
  otherFollowersVariant?: MutualFollowersProps['otherFollowersVariant'];
  onOtherFollowersClick?: MutualFollowersProps['onOtherFollowersClick'];
  variant: MutualFollowersProps['variant'];
  size: UserAvatarVariants['size'];
};

const MemoizedMutualFollowerPopoverLinks = memo(
  function MutualFollowerPopoverLinks(props: MutualFollowerLinksProps) {
    const {
      avatars,
      count,
      otherFollowersVariant = 'DEEP_LINK_TO_MODAL',
      onOtherFollowersClick = noop,
      publicKey,
      variant,
      size,
    } = props;

    const [source, target] = useSingleton();
    const extraCount = count - avatars.length;
    const showExtraCount = Boolean(extraCount) && count > 5;

    if (variant === 'BADGE') {
      return (
        <>
          <ProfileHoverCardSingleton source={source} placement="bottom-end" />
          <FollowedByContentWrapper css={{ gap: '$1' }}>
            <UserStackInteractive
              users={avatars}
              variant="raised"
              target={target}
              size={size}
            />
            {showExtraCount && (
              <ExtraFollowersBadge onClick={() => onOtherFollowersClick()}>
                +{abbreviateValue(extraCount, { roundThousands: true })}
              </ExtraFollowersBadge>
            )}
          </FollowedByContentWrapper>
        </>
      );
    }

    return (
      <>
        <ProfileHoverCardSingleton source={source} placement="bottom-end" />
        <FollowedByContentWrapper css={{ gap: '$1' }}>
          <UserStackInteractive
            users={avatars}
            variant="raised"
            target={target}
            size={size}
          />
          <FollowedByMessage
            avatars={avatars}
            count={count}
            otherFollowersVariant={otherFollowersVariant}
            publicKey={publicKey}
            target={target}
          />
        </FollowedByContentWrapper>
      </>
    );
  }
);

function MutualFollowerLinks(props: MutualFollowerLinksProps) {
  const {
    avatars,
    count,
    otherFollowersVariant = 'DEEP_LINK_TO_MODAL',
    onOtherFollowersClick,
    publicKey,
    size,
  } = props;

  return (
    <FollowedByContentWrapper>
      <UserStack users={avatars} variant="raised" size={size} />
      <FollowedByMessage
        avatars={avatars}
        count={count}
        otherFollowersVariant={otherFollowersVariant}
        onOtherFollowersClick={onOtherFollowersClick}
        publicKey={publicKey}
      />
    </FollowedByContentWrapper>
  );
}

const ExtraFollowersBadge = styled('div', {
  color: '$black70',
  background: '$black5',
  padding: '2px $1',
  borderRadius: '$round',
  fontWeight: '$semibold',
  fontSize: '$0',
  lineHeight: '$1',
});

const FollowedByContentWrapper = styled('div', {
  display: 'flex',
  gap: '$2',
  alignItems: 'center',
});

type FollowedByMessageProps = {
  avatars: UserAvatar[];
  count: number;
  otherFollowersVariant: MutualFollowersProps['otherFollowersVariant'];
  onOtherFollowersClick?: MutualFollowersProps['onOtherFollowersClick'];
  publicKey: string;
  target?: SingletonPopoverObject;
};

export function FollowedByMessage(props: FollowedByMessageProps) {
  const {
    avatars: avatarsInNormalOrder,
    count,
    otherFollowersVariant,
    onOtherFollowersClick = noop,
    publicKey,
    target,
  } = props;

  // We need to reverse the order of the avatars to match the order that
  // they appear in the UI (which also gets reversed via CSS)
  const avatars = [...avatarsInNormalOrder].reverse();

  if (count === 0 || !avatars[0]) {
    return null;
  }

  if (count === 1 && avatars[0]) {
    return (
      <MutualFollowersText>
        Followed by <FollowedByProfileTag target={target} user={avatars[0]} />
      </MutualFollowersText>
    );
  }

  if (count === 2 && avatars[0] && avatars[1]) {
    return (
      <MutualFollowersText>
        Followed by <FollowedByProfileTag target={target} user={avatars[0]} />{' '}
        and <FollowedByProfileTag target={target} user={avatars[1]} />
      </MutualFollowersText>
    );
  }

  const otherFollowers = (
    <Text weight="semibold">{count - 1} others you follow</Text>
  );

  const followedByText = (
    <>
      Followed by <FollowedByProfileTag target={target} user={avatars[0]} /> and
    </>
  );

  return (
    <MutualFollowersText>
      {followedByText}{' '}
      {otherFollowersVariant === 'DEEP_LINK_TO_MODAL' ? (
        <NextLink
          href={getPath.profile.follows(publicKey, 'followers')}
          prefetch={false}
          passHref
        >
          <Link variant="strong">{otherFollowers}</Link>
        </NextLink>
      ) : (
        <Link
          as="button"
          variant="strong"
          onClick={() => onOtherFollowersClick()}
        >
          {otherFollowers}
        </Link>
      )}
    </MutualFollowersText>
  );
}

type FollowedByTextProps = {
  children: React.ReactNode;
};

function MutualFollowersText(props: FollowedByTextProps) {
  return (
    <Text
      css={{
        '& > *': {
          // Make popover trigger align with the text
          display: 'inline-block',
          verticalAlign: 'middle',
        },
      }}
      color="dim"
      size={0}
    >
      {props.children}
    </Text>
  );
}

type FollowedByProfileTagProps = {
  user: UserAvatar;
  target?: SingletonPopoverObject;
};

function FollowedByProfileTag(props: FollowedByProfileTagProps) {
  const { user, target } = props;

  return target ? (
    <FollowedByProfileTagPopover user={user} target={target} />
  ) : (
    <FollowedByProfileTagLink user={user} />
  );
}

type FollowedByProfileTagPopoverProps = {
  user: UserAvatar;
  target: SingletonPopoverObject;
};

function FollowedByProfileTagPopover(props: FollowedByProfileTagPopoverProps) {
  const { user, target } = props;

  return (
    <Tippy content={user?.publicKey} singleton={target}>
      <Box
        css={{
          // Applied when the popover is open
          '&[aria-expanded=true] > *': {
            color: '$black70',
          },
        }}
      >
        <FollowedByProfileTagLink user={user} />
      </Box>
    </Tippy>
  );
}

type FollowedByProfileTagLinkProps = {
  user: UserAvatar;
};

export function FollowedByProfileTagLink(props: FollowedByProfileTagLinkProps) {
  const { user } = props;

  return (
    <NextLink
      href={getPath.profile.page(getUsernameOrAddress(user))}
      prefetch={false}
      passHref
    >
      <Link variant="strong">
        <UserText variant="prefer-username" user={user} />
      </Link>
    </NextLink>
  );
}
