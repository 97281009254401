import { styled } from '@f8n-frontend/stitches';
import { useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';
import { useHoverDirty } from 'react-use';

import SpinnerStroked from 'components/SpinnerStroked';
import { ButtonVariants } from 'components/base/Button';
import Button from 'components/base/Button';
import Skeleton from 'components/base/Skeleton';
import AuthedButton from 'components/buttons/AuthedButton';
import { hasToken, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { useFollow } from 'gql/api/mutations/follow.generated';
import { useUnfollow } from 'gql/api/mutations/unfollow.generated';
import useSegmentEvent from 'hooks/analytics/use-segment-event';
import useIsFollowingUser, {
  IsFollowing,
} from 'hooks/queries/use-is-following-user';
import useModal from 'hooks/use-modal';
import { connectedUserCache } from 'utils/cache';
import { helpCenterPaths } from 'utils/router';

type OnSuccessCallback = (type: 'FOLLOWED' | 'UNFOLLOWED') => void;

type FollowButtonSize = ButtonVariants['size'];

export type FollowButtonProps = {
  isInitiallyFollowing?: boolean;
  onSuccess?: OnSuccessCallback;
  publicKey: string;
  size: FollowButtonSize;
};

/**
 * An auth-guarded FollowButton.
 *
 * Will either allow you to follow a user, or request that you complete the required auth steps first.
 */
export default function FollowButton(props: FollowButtonProps) {
  const { isInitiallyFollowing, onSuccess, size, publicKey } = props;
  const auth = useAuth();

  if (isMyAddress(auth, publicKey)) {
    return null;
  }

  if (hasToken(auth)) {
    return (
      <FollowButtonAuth
        currentUserPublicKey={auth.publicKey}
        isInitiallyFollowing={isInitiallyFollowing}
        onSuccess={onSuccess}
        publicKey={publicKey}
        size={size}
      />
    );
  }

  return (
    <FakeFollowButton isInitiallyFollowing={isInitiallyFollowing} size={size} />
  );
}

type FakeFollowButtonProps = {
  size: FollowButtonSize;
  isInitiallyFollowing: boolean | undefined;
};

/**
 * Looks like a Follow button, but doesn't actually follow or unfollow.
 * Instead, it pushes you through the auth flow, which then allows you to follow afterwards.
 */
function FakeFollowButton(props: FakeFollowButtonProps) {
  const { isInitiallyFollowing, size } = props;

  return (
    <AuthedButton
      variant={isInitiallyFollowing ? 'primary' : 'secondary'}
      size={size}
    >
      {isInitiallyFollowing ? 'Unfollow' : 'Follow'}
    </AuthedButton>
  );
}

type UseFollowOptions = {
  currentUserPublicKey: string;
  isInitiallyFollowing: boolean | undefined;
  onSuccess?: OnSuccessCallback;
  publicKey: string;
};

const useFollowState = (options: UseFollowOptions) => {
  const {
    currentUserPublicKey: currentUserAccountAddress,
    isInitiallyFollowing,
    onSuccess,
    publicKey: accountAddress,
  } = options;

  const sendSegmentEvent = useSegmentEvent();
  const queryClient = useQueryClient();
  const { setModal } = useModal();

  const followQuery = useIsFollowingUser(
    { accountAddress },
    {
      // Only fetch if initial state is not known
      enabled: isInitiallyFollowing === undefined,
      placeholderData: Boolean(isInitiallyFollowing),
    }
  );

  const handleSuccess: OnSuccessCallback = (followType) => {
    if (onSuccess) {
      onSuccess(followType);
    }

    queryClient.setQueryData<IsFollowing>(
      connectedUserCache.isFollowing(accountAddress),
      followType === 'FOLLOWED' ? true : false
    );

    sendSegmentEvent({
      eventName: followType === 'FOLLOWED' ? 'follow_create' : 'follow_remove',
      payload: {
        followedUser: accountAddress,
        followingUser: currentUserAccountAddress,
      },
    });
  };

  const followMutation = useFollow({
    onSuccess: () => {
      handleSuccess('FOLLOWED');
    },
    onError: () => {
      setModal({
        type: 'NOTICE',
        title: 'Limit reached',
        body: 'You are unable to follow more people at this time. To protect our community, we limit when and how often you can follow people.',
        learnMoreLink: helpCenterPaths.policies.spamFollow,
      });
    },
  });

  const unfollowMutation = useUnfollow({
    onSuccess: () => {
      handleSuccess('UNFOLLOWED');
    },
  });

  const isFollowing = followQuery.data;

  if (isFollowing) {
    return {
      isFollowing,
      isMutating: unfollowMutation.isPending,
      toggle: () => unfollowMutation.mutate({ accountAddress }),
    };
  } else {
    return {
      isFollowing,
      isMutating: followMutation.isPending,
      toggle: () => followMutation.mutate({ accountAddress }),
    };
  }
};

type FollowButtonAuthProps = {
  currentUserPublicKey: string;
  isInitiallyFollowing: boolean | undefined;
  onSuccess?: OnSuccessCallback;
  publicKey: string;
  size: FollowButtonSize;
};

function FollowButtonAuth(props: FollowButtonAuthProps) {
  const {
    currentUserPublicKey,
    isInitiallyFollowing,
    publicKey,
    onSuccess,
    size = 1,
  } = props;
  const unFollowHoverRef = useRef<HTMLButtonElement>(null);
  const isHoveringUnfollow = useHoverDirty(unFollowHoverRef);

  const follow = useFollowState({
    currentUserPublicKey,
    isInitiallyFollowing,
    onSuccess,
    publicKey,
  });

  if (follow.isMutating) {
    return (
      <BaseFollowButton
        variant="primary"
        size={size}
        css={{ pointerEvents: 'none' }}
      >
        <SpinnerStroked />
      </BaseFollowButton>
    );
  }

  if (follow.isFollowing) {
    return (
      <BaseFollowButton
        ref={unFollowHoverRef}
        variant={isHoveringUnfollow ? 'danger' : 'primary'}
        size={size}
        onClick={() => follow.toggle()}
      >
        {isHoveringUnfollow ? 'Unfollow' : 'Following'}
      </BaseFollowButton>
    );
  }

  return (
    <BaseFollowButton
      variant="secondary"
      size={size}
      onClick={() => follow.toggle()}
    >
      Follow
    </BaseFollowButton>
  );
}

const FollowButtonShape = styled('div', {
  // We need min-width to make sure there's no content shift when changing
  // between the words Follow, Unfollow and Following
  compoundVariants: [
    {
      size: 0,
      css: {
        minWidth: 98,
        // Loading svg
        svg: {
          width: '$icon1',
          height: '$icon1',
        },
      },
    },
    {
      size: 1,
      css: {
        minWidth: 124,
        // Loading svg
        svg: {
          width: '$icon2',
          height: '$icon2',
        },
      },
    },
    {
      size: 2,
      css: {
        minWidth: 148,
        // Loading svg
        svg: {
          width: '$icon3',
          height: '$icon3',
        },
      },
    },
  ],
});

const BaseFollowButton = styled(Button, FollowButtonShape, {
  whiteSpace: 'nowrap',
  fontSize: '$3',
});

const BaseFollowButtonSkeleton = styled(FollowButtonShape, Skeleton.Button);

FollowButton.Skeleton = BaseFollowButtonSkeleton;
